<template>
  <b-modal
    id="line-modal"
    v-model="modal"
    title="Connect Line"
    hide-footer
    @hide="handleModalHidden"
  >
    <b-row>
      <b-col sm="12">
        <InputText
          textFloat="Shopname"
          placeholder="Shopname"
          type="text"
          name="Shopname"
          :v="$v.form.shopName"
          v-model="form.shopName"
        />
      </b-col>
      <b-col sm="12">
        <div class="position-icon">
          <button
            type="button"
            class="btn-icon mr-1 error-icon"
            v-if="hasError == true"
          >
            <font-awesome-icon icon="times" />
          </button>
          <button
            type="button"
            class="btn-icon mr-1 success"
            v-else-if="hasError == 'hasVerify'"
          >
            <font-awesome-icon icon="check" />
          </button>
        </div>
        <InputText
          textFloat="API-KEY"
          placeholder="API-KEY"
          type="text"
          name="API-KEY"
          :v="$v.form.apiKey"
          @onKeypress="clearError"
          v-model="form.apiKey"
        />
      </b-col>
    </b-row>

    <b-button
      class="main-color btn-save mb-3"
      @click="verify()"
      :disabled="form.apiKey == ''"
    >
      Verify
    </b-button>
    <b-row>
      <b-col cols="12">
        <div class="position-icon">
          <button
            type="button"
            class="btn-icon mr-1"
            v-clipboard:copy="form.url_key"
          >
            <font-awesome-icon icon="copy" />
          </button>
        </div>
        <InputText
          textFloat="Url Key"
          placeholder="Url Key"
          type="text"
          name="urlkey"
          v-model="form.url_key"
          :readonly="true"
        />
      </b-col>
    </b-row>
    <!-- <b-row
      ><b-col cols="12">
        <InputText
          textFloat="Secret Key"
          placeholder="Secret Key"
          type="text"
          name="secretKey"
          v-model="form.secret_key"
          v-if="form.url_key && form.url_key != ''" /></b-col
    ></b-row> -->
    <div class="d-flex justify-content-between w-100 mt-3">
      <b-button
        class="btn-cancel btn-width"
        @click="$bvModal.hide('line-modal')"
        >Cancel
      </b-button>
      <b-button class="main-color btn-save" @click="createLine()">
        <!-- :disabled="hasError != 'hasVerify'" -->
        Save
      </b-button>
    </div>
    <!-- <template #modal-footer class="py-0"> </template> -->
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: { data: { required: false } },
  data() {
    return {
      modal: false,
      form: {
        shopName: "",
        apiKey: "",
        url_key: "",
        branchMarketPlaceId: 0,
        secret_key: "",
      },

      hasError: false,
    };
  },
  validations: {
    form: {
      shopName: {
        required,
      },
      apiKey: {
        required,
      },
    },
  },
  watch: {
    data(val) {
      this.form.apiKey = val.api_key;
      this.form.branchMarketPlaceId = val.id;
      this.form.shopName = val.shopName;
      this.form.url_key = val.url_key;
    },
  },
  methods: {
    handleModalHidden() {
      this.form = {
        shopName: "",
        apiKey: "",
        url_key: "",
        branchMarketPlaceId: 0,
        secret_key: "",
      };
      this.$v.$reset();
      this.hasError = false;
    },
    openModal() {
      this.modal = true;
    },
    async verify() {
      this.$EventBus.$emit("showLoading");
      this.hasError = false;
      const res = await this.$Axios(
        `https://crm-shopee-dev.dosetech.co/api/Line/VerifyToken/${this.form.apiKey}`
      );
      this.$EventBus.$emit("hideLoading");
      if (res.data.result != 0) {
        // this.successAlert();
        this.form.url_key = res.data.detail;
        this.hasError = "hasVerify";
      } else {
        this.hasError = true;
      }
    },
    clearError() {
      this.hasError = false;
    },
    async createLine() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$EventBus.$emit("showLoading");
      let location = window.location.hostname;
      let brand = location.split("-")[0];
      let code = "ALODEV";
      if (brand == "nissin") code = "NS";
      else if (brand == "seasun") code = "SEASUN";
      else if (brand == "lolane") code = "LOLANE";
      else if (brand == "yongcharoen") code = "YCR";
      let payload = {
        code: this.form.apiKey,
        branchName: this.form.shopName,
        branchMarketPlaceId: this.form.branchMarketPlaceId,
        secret_key: this.form.secret_key,
        brand_code: code,
      };
      const res = await this.$Axios.post(
        `https://crm-shopee-dev.dosetech.co/api/Line/getToken`,
        payload
      );
      this.$EventBus.$emit("hideLoading");
      if (res.data.result != 0) {
        // this.$bvModal.hide("line-modal");
        this.form.url_key = res.data.detail;
        this.successAlert();
        this.$emit("fetch");
      } else {
        this.errorAlert(res.data.message);
        // this.hasError = true;
      }
    },
  },
};
</script>

<style>
.position-icon {
  position: absolute;
  z-index: 999;
  left: 80px;
}

.btn-icon {
  background-color: transparent;
  border: none;
  font-size: 16px;
}
.error-icon {
  background: red;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 10px;
}
.success {
  background: rgb(25, 190, 25);
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 10px;
}
</style>
